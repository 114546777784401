const classNames = [

	'career-request',
	'career-request-tablet',
	'career-request-mobile',

	'careerum-another-goals',
	'careerum-another-goals-tablet',
	'careerum-another-goals-mobile',

	'careerum-getcourse-email-form',
	'careerum-getcourse-email-form-tablet',
	'careerum-getcourse-email-form-mobile',

	'careerum-club-main',
	'careerum-club-main-tablet',
	'careerum-club-main-mobile',

	'careerum-for-you-or-not',
	'careerum-for-you-or-not-tablet',
	'careerum-for-you-or-not-mobile',

	'careerum-study-process',
	'careerum-study-process-tablet',
	'careerum-study-process-mobile',

	'careerum-study-process-2',
	'careerum-study-process-2-tablet',
	'careerum-study-process-2-mobile',
	
	'careerum-coach-support',
	'careerum-coach-support-tablet',
	'careerum-coach-support-mobile',
	
	'careerum-calendar',
	'careerum-calendar-tablet',
	'careerum-calendar-mobile',
	
	'careerum-results-new',
	'careerum-results-new-tablet',
	'careerum-results-new-mobile',
	
	'careerum-main-block',
	'careerum-main-block-tablet',
	'careerum-main-block-mobile',
	
	'careerum-tales-with-header',
	'careerum-tales-with-header-tablet',
	'careerum-tales-with-header-mobile',

	'careerum-video-and-buttons',
	'careerum-video-and-buttons-tablet',
	'careerum-video-and-buttons-mobile',	

	'careerum-banner-with-buttons',
	'careerum-banner-with-buttons-tablet',
	'careerum-banner-with-buttons-mobile',	

	'careerum-tabs-horizontal',
	'careerum-tabs-horizontal-tablet',
	'careerum-tabs-horizontal-mobile',	

	'careerum-tabs-vertical',
	'careerum-tabs-vertical-tablet',
	'careerum-tabs-vertical-mobile',	

	'careerum-three-tales',
	'careerum-three-tales-tablet',
	'careerum-three-tales-mobile',	

	'careerum-vacancy',
	'careerum-vacancy-tablet',
	'careerum-vacancy-mobile',	

	'careerum-upsale-main',
	'careerum-upsale-main-tablet',
	'careerum-upsale-main-mobile',	

	'careerum-upsale-tariffs-three',
	'careerum-upsale-tariffs-three-tablet',
	'careerum-upsale-tariffs-three-mobile',	

	'careerum-upsale-tariffs-two',
	'careerum-upsale-tariffs-two-tablet',
	'careerum-upsale-tariffs-two-mobile',	

	'careerum-upsale-tariffs-one',
	'careerum-upsale-tariffs-one-tablet',
	'careerum-upsale-tariffs-one-mobile',



	'careerum-veb-main',
	'careerum-veb-main-tablet',
	'careerum-veb-main-mobile',

	'careerum-veb-for-you',
	'careerum-veb-for-you-tablet',
	'careerum-veb-for-you-mobile',

	'careerum-veb-author',
	'careerum-veb-author-tablet',
	'careerum-veb-author-mobile',

	'careerum-veb-rezult',
	'careerum-veb-rezult-tablet',
	'careerum-veb-rezult-mobile',

	'careerum-veb-rezult-2col',
	'careerum-veb-rezult-2col-tablet',

	'careerum-veb-speakers',
	'careerum-veb-speakers-tablet',
	'careerum-veb-speakers-mobile',

	'careerum-veb-tariffs',
	'careerum-veb-tariffs-tablet',
	'careerum-veb-tariffs-mobile',

	'careerum-veb-find-work',
	'careerum-veb-find-work-tablet',
	'careerum-veb-find-work-mobile',

	'careerum-veb-results',
	'careerum-veb-results-tablet',
	'careerum-veb-results-mobile',

	'careerum-veb-subscribe',
	'careerum-veb-subscribe-tablet',
	'careerum-veb-subscribe-mobile',

	'careerum-tariff-page',
	'careerum-tariff-page-tablet',
	'careerum-tariff-page-mobile',

	'careerum-registration-form',
	'careerum-registration-form-tablet',
	'careerum-registration-form-mobile',


	'careerum-404',
	'careerum-404-tablet',
	'careerum-404-mobile',
	

	'careerum-instagram-main',
	'careerum-instagram-main-tablet',
	'careerum-instagram-main-mobile',

	'careerum-free-materials',
	'careerum-free-materials-tablet',
	'careerum-free-materials-mobile',

	'careerum-instagram-goals',
	'careerum-instagram-goals-tablet',
	'careerum-instagram-goals-mobile',

	'careerum-instagram-subscribe',
	'careerum-instagram-subscribe-tablet',
	'careerum-instagram-subscribe-mobile',

	'careerum-tips-main',
	'careerum-tips-main-tablet',
	'careerum-tips-main-mobile',

	'careerum-tips-about',
	'careerum-tips-about-tablet',
	'careerum-tips-about-mobile',

	'careerum-tips-tabs',
	'careerum-tips-tabs-tablet',
	'careerum-tips-tabs-mobile',

	'careerum-tips-subscribe',
	'careerum-tips-subscribe-tablet',
	'careerum-tips-subscribe-mobile',

	'careerum-getcourse-email-form-2-fields',
	'careerum-getcourse-email-form-2-fields-tablet',
	'careerum-getcourse-email-form-2-fields-mobile',

	'careerum-getcourse-email-form-3-fields',
	'careerum-getcourse-email-form-3-fields-tablet',
	'careerum-getcourse-email-form-3-fields-mobile',

	'careerum-tips-tariffs',
	'careerum-tips-tariffs-tablet',
	'careerum-tips-tariffs-mobile',


	'careerum-vacancy-main',
	'careerum-vacancy-main-tablet',
	'careerum-vacancy-main-mobile',

	'careerum-vacancy-tiles',
	'careerum-vacancy-tiles-tablet',
	'careerum-vacancy-tiles-mobile',

	'careerum-vacancy-tabs',
	'careerum-vacancy-tabs-tablet',
	'careerum-vacancy-tabs-mobile',

	'careerum-interblock-timer',
	'careerum-interblock-timer-tablet',
	'careerum-interblock-timer-mobile',

	'careerum-reserve-main',
	'careerum-reserve-main-tablet',
	'careerum-reserve-main-mobile',

	'careerum-thanks-page1',
	'careerum-thanks-page1-tablet',
	'careerum-thanks-page1-mobile',

	'careerum-thanks-page2',
	'careerum-thanks-page2-tablet',
	'careerum-thanks-page2-mobile',

	'careerum-faq2-block',
	'careerum-faq2-block-tablet',
	'careerum-faq2-block-mobile',
	
	// Fix for tabs
	'careerum-faq2-tab',
	'careerum-results-tab',

	'careerum-results-tabs-block',
	'careerum-results-tabs-block-tablet',
	'careerum-results-tabs-block-mobile',

	'careerum-case-main',
	'careerum-case-main-tablet',
	'careerum-case-main-mobile',

	'careerum-lead-form-row',
	'careerum-lead-form-row-tablet',
	'careerum-lead-form-row-mobile',

	'careerum-advent-calendar',
	'careerum-advent-calendar-tablet',
	'careerum-advent-calendar-mobile',

	'careerum-club-banner',
	'careerum-club-banner-tablet',
	'careerum-club-banner-mobile',

	'careerum-club-small-banner',
	'careerum-club-small-banner-tablet',
	'careerum-club-small-banner-mobile',

	'careerum-cons-main',
	'careerum-cons-main-tablet',
	'careerum-cons-main-mobile',

	'careerum-cons-tales',
	'careerum-cons-tales-tablet',
	'careerum-cons-tales-mobile',

	'careerum-cons-about',
	'careerum-cons-about-tablet',
	'careerum-cons-about-mobile',

	'careerum-cons-tariff-block',
	'careerum-cons-tariff-block-tablet',
	'careerum-cons-tariff-block-mobile',

	'careerum-cons-faq-block',
	'careerum-cons-faq-block-tablet',
	'careerum-cons-faq-block-mobile',

	'careerum-cons-tariff-item',
	'careerum-consult-tariff-tab',

	'careerum-popup',

	'careerum-vorkr-form',
	'careerum-vorkr-form-tablet',
	'careerum-vorkr-form-mobile',

	'careerum-vorkr-tabs-col',
	'careerum-vorkr-tabs-col-tablet',
	'careerum-vorkr-tabs-col-mobile',

	'careerum-vorkr-tabs-row-button',
	'careerum-vorkr-tabs-row-button-tablet',
	'careerum-vorkr-tabs-row-button-mobile',

	'careerum-vorkr-tabs-accord-block',
	'careerum-vorkr-tabs-accord-block-tablet',
	'careerum-vorkr-tabs-accord-block-mobile',

	'careerum-vorkr-tabs-row',
	'careerum-vorkr-tabs-row-tablet',
	'careerum-vorkr-tabs-row-mobile',

	'careerum-vorkr-results',
	'careerum-vorkr-results-tablet',
	'careerum-vorkr-results-mobile',

	'careerum-vorkr-thanks',
	'careerum-vorkr-thanks-tablet',
	'careerum-vorkr-thanks-mobile',

	'careerum-lead-form-sheets-row',
	'careerum-lead-form-sheets-row-tablet',
	'careerum-lead-form-sheets-row-mobile',

	'careerum-club2-main',
	'careerum-club2-main-tablet',
	'careerum-club2-main-mobile',

	'careerum-club2-banner',
	'careerum-club2-banner-tablet',
	'careerum-club2-banner-mobile',

	'careerum-club2-results',
	'careerum-club2-results-tablet',
	'careerum-club2-results-mobile',

	'careerum-club2-goals',
	'careerum-club2-goals-tablet',
	'careerum-club2-goals-mobile',

	'careerum-club2-subscribe',
	'careerum-club2-subscribe-tablet',
	'careerum-club2-subscribe-mobile',

	'careerum-club2-network',
	'careerum-club2-network-tablet',
	'careerum-club2-network-mobile',

	'careerum-club2-coaches',
	'careerum-club2-coaches-tablet',
	'careerum-club2-coaches-mobile',

	'careerum-club2-resgoals',
	'careerum-club2-resgoals-tablet',
	'careerum-club2-resgoals-mobile',

	'careerum-club2-tariffs',
	'careerum-club2-tariffs-tablet',
	'careerum-club2-tariffs-mobile',

	'careerum-club2-tariffs-rassr',
	'careerum-club2-tariffs-rassr-tablet',
	'careerum-club2-tariffs-rassr-mobile',

	'careerum-club2-popup-block-pay',
	'careerum-club2-popup-block-pay-rassr',
	'careerum-club2-popup-block-subscribe',
	'no-cabinet',
	'has-cabinet',
	'careerum-club2-popup-block-tariffs',
	'popup-tariff__item',
	
	'club2-tariffs-item',
	'club21-tariffs-item',
	'careerum-club2-popup__period-switcher',

	'careerum-thanks-page',
	'careerum-thanks-page-tablet',
	'careerum-thanks-page-mobile',

	'careerum-club21-main',
	'careerum-club21-main-tablet',
	'careerum-club21-main-mobile',

	'careerum-club21-tools',
	'careerum-club21-tools-tablet',
	'careerum-club21-tools-mobile',

	'careerum-getcourse-email-form-4-fields',
	'careerum-getcourse-email-form-4-fields-tablet',
	'careerum-getcourse-email-form-4-fields-mobile',

	'careerum-club21-banner',
	'careerum-club21-banner-tablet',
	'careerum-club21-banner-mobile',

	'careerum-club21-programs',
	'careerum-club21-programs-tablet',
	'careerum-club21-programs-mobile',

	'careerum-club21-authors',
	'careerum-club21-authors-tablet',
	'careerum-club21-authors-mobile',

	'careerum-club21-calendar',
	'careerum-club21-calendar-tablet',
	'careerum-club21-calendar-mobile',

	'careerum-club21-coaches',
	'careerum-club21-coaches-tablet',
	'careerum-club21-coaches-mobile',

	'careerum-club21-tariffs',
	'careerum-club21-tariffs-tablet',
	'careerum-club21-tariffs-mobile',

	'careerum-club21-results',
	'careerum-club21-results-tablet',
	'careerum-club21-results-mobile',

	'careerum-club21-faq',
	'careerum-club21-faq-tablet',
	'careerum-club21-faq-mobile',

	'club21-programs-item',
	'careerum-club21-popup-block-info',

	'careerum-veb20-main',
	'careerum-veb20-main-tablet',
	'careerum-veb20-main-mobile',

	'careerum-veb20-about',
	'careerum-veb20-about-tablet',
	'careerum-veb20-about-mobile',

	'careerum-veb20-author',
	'careerum-veb20-author-tablet',
	'careerum-veb20-author-mobile',

	'careerum-club21-banner20',
	'careerum-club21-banner20-tablet',
	'careerum-club21-banner20-mobile',

	'careerum-club21-banner20',
	'careerum-club21-banner20-tablet',
	'careerum-club21-banner20-mobile',

	'careerum-club21-banner21',
	'careerum-club21-banner21-tablet',
	'careerum-club21-banner21-mobile',

	'careerum-club21-banner22',
	'careerum-club21-banner22-tablet',
	'careerum-club21-banner22-mobile',

	'careerum-club21-banner23',
	'careerum-club21-banner23-tablet',
	'careerum-club21-banner23-mobile',

	'careerum-club21-banner24',
	'careerum-club21-banner24-tablet',
	'careerum-club21-banner24-mobile',

	'careerum-club21-banner-top',
	'careerum-club21-banner-top-tablet',
	'careerum-club21-banner-top-mobile',

	'careerum-sert-main',
	'careerum-sert-main-tablet',
	'careerum-sert-main-mobile',

	'careerum-sert-how',
	'careerum-sert-how-tablet',
	'careerum-sert-how-mobile',

	'careerum-round-separator',
	'careerum-round-separator-tablet',
	'careerum-round-separator-mobile',

	'careerum-consultations-main',
	'careerum-consultations-main-tablet',
	'careerum-consultations-main-mobile',

	'careerum-consultations-about',
	'careerum-consultations-about-tablet',
	'careerum-consultations-about-mobile',
	
	'careerum-consultations-goals',
	
	'careerum-consultations-goals-tab',
	
	'careerum-header30',
	'careerum-club30-main',
	'careerum-club30-main2',
	'careerum-club30-main3',
	'careerum-club30-reasons',
	'careerum-club30-reasons-accordeon-item',
	'careerum-club30-goals',
	'careerum-club30-goals-tab',
	'careerum-club30-tools',
	'careerum-club30-authors',
	'careerum-club30-subscribe',
	'careerum-footer30',

	'careerum-new-year-decor',
	'careerum-new-year-decor-tablet',
	'careerum-new-year-decor-mobile',

	'careerum-realme-main',
	'careerum-realme-main-tablet',
	'careerum-realme-main-mobile',

	'careerum-realme-pains',
	'careerum-realme-pains-tablet',
	'careerum-realme-pains-mobile',

	'careerum-realme-for-what',
	'careerum-realme-for-what-tablet',
	'careerum-realme-for-what-mobile',

	'careerum-realme-balance',
	'careerum-realme-balance-tablet',
	'careerum-realme-balance-mobile',

	'careerum-realme-skills',
	'careerum-realme-skills-tablet',
	'careerum-realme-skills-mobile',

	'careerum-realme-how-it',
	'careerum-realme-how-it-tablet',
	'careerum-realme-how-it-mobile',

	'careerum-realme-author',
	'careerum-realme-author-tablet',
	'careerum-realme-author-mobile',

	'careerum-business-main',
	'careerum-business-main-tablet',
	'careerum-business-main-mobile',

	'careerum-business-companies',
	'careerum-business-companies-tablet',
	'careerum-business-companies-mobile',

	'careerum-business-advantages',
	'careerum-business-advantages-tablet',
	'careerum-business-advantages-mobile',

	'careerum-business-about',
	'careerum-business-about-tablet',
	'careerum-business-about-mobile',

	'careerum-business-form',
	'careerum-business-form-tablet',
	'careerum-business-form-mobile',

	'careerum-thanks-popup',

    'careerum-intens-main',
    'careerum-intens-main-tablet',
    'careerum-intens-main-mobile',

    'careerum-intens-bonus',
    'careerum-intens-bonus-tablet',
    'careerum-intens-bonus-mobile',

    'careerum-intens-how',
    'careerum-intens-how-tablet',
    'careerum-intens-how-mobile',

    'careerum-intens-resume',
    'careerum-intens-resume-tablet',
    'careerum-intens-resume-mobile',

    'careerum-intens-before',
    'careerum-intens-before-tablet',
    'careerum-intens-before-mobile',

    'careerum-intens-after',
    'careerum-intens-after-tablet',
    'careerum-intens-after-mobile',

    'careerum-intens-author',
    'careerum-intens-author-tablet',
    'careerum-intens-author-mobile',

    'careerum-intens-product',
    'careerum-intens-product-tablet',

    'careerum-intens-result',
    'careerum-intens-result-tablet',
    'careerum-intens-result-mobile',

    'careerum-intens-form',
    'careerum-intens-form-tablet',
    'careerum-intens-form-mobile',

    'careerum-case2-main',
    'careerum-case2-main-tablet',
    'careerum-case2-main-mobile',

    'careerum-case2-content-block',
    'careerum-case2-content-block-tablet',
    'careerum-case2-content-block-mobile',

    'careerum-business-tasks',
    'careerum-business-tasks-tablet',
    'careerum-business-tasks-mobile',

    'careerum-veb21-main',
    'careerum-veb21-main-tablet',
    'careerum-veb21-main-mobile',

    'careerum-veb21-tales',
    'careerum-veb21-tales-tablet',
    'careerum-veb21-tales-mobile',

    'careerum-veb21-3tales',
    'careerum-veb21-3tales-tablet',
    'careerum-veb21-3tales-mobile',

    'careerum-veb21-author',
    'careerum-veb21-author-tablet',
    'careerum-veb21-author-mobile',

    'careerum-veb21-reviews',
    'careerum-veb21-reviews-tablet',
    'careerum-veb21-reviews-mobile',

    'cookie-banners',
	
	'careerum-club21-banner-top2',
	'careerum-club21-banner-top2-tablet',
	'careerum-club21-banner-top2-mobile',
	
	'careerum-club21-banner-top3',
	'careerum-club21-banner-top3-tablet',
	'careerum-club21-banner-top3-mobile',
	
	'careerum-veb22-main',
	'careerum-veb22-main-tablet',
	'careerum-veb22-main-mobile',
	
	'careerum-veb22-main',
	'careerum-veb22-main-tablet',
	'careerum-veb22-main-mobile',
	
	'careerum-veb22-form',
	'careerum-veb22-form-tablet',
	'careerum-veb22-form-mobile',
	
	'careerum-veb30-main',
	'careerum-veb30-main-tablet',
	'careerum-veb30-main-mobile',
	
	'careerum-veb30-main2',
	'careerum-veb30-main2-tablet',
	'careerum-veb30-main2-mobile',
	
	'careerum-veb30-for',
	'careerum-veb30-for-tablet',
	'careerum-veb30-for-mobile',
	
	'careerum-veb30-programm',
	'careerum-veb30-programm-tablet',
	'careerum-veb30-programm-mobile',
	
	'careerum-veb30-speaker',
	'careerum-veb30-speaker-tablet',
	'careerum-veb30-speaker-mobile',
	
	'careerum-veb30-how',
	'careerum-veb30-how-tablet',
	'careerum-veb30-how-mobile',
	
	'careerum-veb30-form-block',
	'careerum-veb30-form-block-tablet',
	'careerum-veb30-form-block-mobile',
	
	'veb22-tariffs',
	'veb22-tariffs-tablet',
	'veb22-tariffs-mobile',
	

];

export default classNames;